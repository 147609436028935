<template>
  <div class="adhocCharges">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div :class="selectedHistoricRates ? 'big-container' : 'container'">
      <v-card :loading="loadingData" class="mb-4">
        <v-form class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <company-select
                @chosen="checkCompanySelected"
                v-model="companySelected"
              ></company-select>
              <v-col cols="6">
                <v-select
                  v-if="isPrepCompany"
                  menu-props="auto"
                  v-model="selectedPrepId"
                  :items="prepCompaniesList"
                  item-text="name"
                  item-value="id"
                  label="Select Prep Company"
                  outlined
                  dense
                  @change="loadCompanyRates(companySelected)"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="historicRates && historicRates.length">
              <v-col cols="4">
                <v-checkbox
                  v-model="compareHistoricRates"
                  label="Compare Previous Rates"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-if="compareHistoricRates"
                  menu-props="auto"
                  v-model="selectedHistoricRates"
                  :items="historicRates"
                  :item-text="
                    (item) => formatDateString(item.globalTariffs.createdAt)
                  "
                  return-object
                  label="Previous Rates"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-row>
        <!-- card showing selected historic rates -->
        <v-col cols="6" v-if="compareHistoricRates && selectedHistoricRates">
          <v-card class="pa-2">
            <v-form @submit.prevent class="ma-3" ref="form">
              <div class="container pa-2">
                <v-row>
                  <v-col cols="8">
                    <h1>
                      Previous Rates From:
                      {{
                        formatDateString(
                          selectedHistoricRates.globalTariffs.createdAt
                        )
                      }}
                    </h1>
                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <!-- Inbounds tariffs -->
                <v-row>
                  <v-col cols="2">
                    <b> Inbound Unloading Charges:</b>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Palletised Inbounds Tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .palletised_inbounds_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .palletised_inbounds_tariff,
                          companyRatesData.globalTariffs
                            .palletised_inbounds_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                    <v-col cols="2">
                    <v-text-field
                      label="Carton Inbounds Tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .carton_inbound_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .carton_inbound_tariff,
                          companyRatesData.globalTariffs
                            .carton_inbound_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="20 Foot Loose Cartons"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          ['20_ft_lo_cart_inbounds_tariff']
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                              ['20_ft_lo_cart_inbounds_tariff'],
                          companyRatesData.globalTariffs
                              ['20_ft_lo_cart_inbounds_tariff']
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  
                       <v-col cols="2">
                    <v-text-field
                      label="40 Foot Loose Cartons"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          ['40_ft_lo_cart_inbounds_tariff']
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                              ['40_ft_lo_cart_inbounds_tariff'],
                          companyRatesData.globalTariffs
                              ['40_ft_lo_cart_inbounds_tariff']
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>

                       <v-col cols="2">
                    <v-text-field
                      label="20 Foot High Cube Loose Cartons"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          ['20_ft_cube_lo_cart_inbounds_tariff']
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                              ['20_ft_cube_lo_cart_inbounds_tariff'],
                          companyRatesData.globalTariffs
                              ['20_ft_cube_lo_cart_inbounds_tariff']
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>

                       <v-col cols="2">
                    <v-text-field
                      label="40 Foot High Cube Loose Cartons"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          ['40_ft_cube_lo_cart_inbounds_tariff']
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                              ['40_ft_cube_lo_cart_inbounds_tariff'],
                          companyRatesData.globalTariffs
                              ['40_ft_cube_lo_cart_inbounds_tariff']
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- Amazon surcharge -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .amazon_surcharge_union
                      "
                      label="Amazon Surcharge"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Amazon Charge"
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .amazon_surcharge_union
                      "
                      v-model="
                        selectedHistoricRates.globalTariffs.amazon_charge
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs.amazon_charge,
                          companyRatesData.globalTariffs.amazon_charge
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2"> </v-col>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="selectedHistoricRates.globalTariffs.sfp_union"
                      label="SFP Surcharge"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="SFP Charge"
                      v-show="selectedHistoricRates.globalTariffs.sfp_union"
                      v-model="selectedHistoricRates.globalTariffs.sfp_tariff"
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs.sfp_tariff,
                          companyRatesData.globalTariffs.sfp_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- order admin -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs.order_admin_union
                      "
                      label="Order Admin"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs.order_admin_union
                      "
                      label="Order Admin Tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs.order_admin_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .order_admin_tariff,
                          companyRatesData.globalTariffs.order_admin_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- label paperwork -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .label_paperwork_union
                      "
                      label="Label Paperwork"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .label_paperwork_union
                      "
                      label="Label Tariff"
                      v-model="selectedHistoricRates.globalTariffs.label_tariff"
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs.label_tariff,
                          companyRatesData.globalTariffs.label_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- loadout -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs.load_out_union
                      "
                      label="Loadout"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs.load_out_union
                      "
                      label="Loadout Tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs.loadout_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs.loadout_tariff,
                          companyRatesData.globalTariffs.loadout_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- picking -->
                <v-row class="mb-n6">
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs.picking_union
                      "
                      label="Picking"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="10">
                    <v-row>
                      <v-col cols="2">
                        <v-text-field
                          label="Secondary Min (N.Pieces)"
                          v-show="
                            selectedHistoricRates.globalTariffs.picking_union
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs.secondary_min
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs.secondary_min,
                              companyRatesData.globalTariffs.secondary_min
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          label="Secondary Max (N.Pieces)"
                          v-show="
                            selectedHistoricRates.globalTariffs.picking_union
                          "
                          :value="
                            selectedHistoricRates.globalTariffs.secondary_max
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs.secondary_max
                          "
                          :rules="
                            selectedHistoricRates.globalTariffs.picking_union
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs.secondary_max,
                              companyRatesData.globalTariffs.secondary_max
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="2">
                        <v-text-field
                          label="First Piece Tariff"
                          v-show="
                            selectedHistoricRates.globalTariffs.picking_union
                          "
                          :value="
                            selectedHistoricRates.globalTariffs
                              .first_piece_tariff
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .first_piece_tariff
                          "
                          :rules="
                            selectedHistoricRates.globalTariffs.picking_union
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs
                                .first_piece_tariff,
                              companyRatesData.globalTariffs.first_piece_tariff
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          label="Secondary Tariff"
                          v-show="
                            selectedHistoricRates.globalTariffs.picking_union
                          "
                          :value="
                            selectedHistoricRates.globalTariffs.secondary_tariff
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs.secondary_tariff
                          "
                          :rules="
                            selectedHistoricRates.globalTariffs.picking_union
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs
                                .secondary_tariff,
                              companyRatesData.globalTariffs.secondary_tariff
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          label="Supplementary Tariff"
                          v-show="
                            selectedHistoricRates.globalTariffs.picking_union
                          "
                          :value="
                            selectedHistoricRates.globalTariffs
                              .supplementary_tariff
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .supplementary_tariff
                          "
                          :rules="
                            companyRatesData.globalTariffs.picking_union
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs
                                .supplementary_tariff,
                              companyRatesData.globalTariffs
                                .supplementary_tariff
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          v-show="
                            selectedHistoricRates.globalTariffs.picking_union
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .picking_break_per_sku
                          "
                          label="Break Per SKU"
                          disabled
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="2">
                        <v-checkbox
                          v-show="
                            selectedHistoricRates.globalTariffs.picking_union
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .charge_pallet_tariff
                          "
                          label="Charge Pallet Tariff"
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Pallet Tariff"
                          v-show="
                            selectedHistoricRates.globalTariffs
                              .charge_pallet_tariff
                          "
                          :value="
                            selectedHistoricRates.globalTariffs.pallet_tariff
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs.pallet_tariff
                          "
                          :rules="
                            selectedHistoricRates.globalTariffs
                              .charge_pallet_tariff
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs.pallet_tariff,
                              companyRatesData.globalTariffs.pallet_tariff
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          v-show="
                            selectedHistoricRates.globalTariffs.picking_union
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .charge_box_tariff
                          "
                          label="Charge Box Tariff"
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-show="
                            selectedHistoricRates.globalTariffs
                              .charge_box_tariff
                          "
                          label="Carton Tariff"
                          :value="
                            selectedHistoricRates.globalTariffs.carton_tariff
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs.carton_tariff
                          "
                          :rules="
                            selectedHistoricRates.globalTariffs
                              .charge_box_tariff
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs.carton_tariff,
                              companyRatesData.globalTariffs.carton_tariff
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          v-show="
                            selectedHistoricRates.globalTariffs.picking_union
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .charge_inner_tariff
                          "
                          label="Charge Inner Tariff"
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          label="Inner Tariff"
                          v-show="
                            selectedHistoricRates.globalTariffs
                              .charge_inner_tariff
                          "
                          :value="
                            selectedHistoricRates.globalTariffs.inner_tariff
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs.inner_tariff
                          "
                          :rules="
                            selectedHistoricRates.globalTariffs
                              .charge_inner_tariff
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs.inner_tariff,
                              companyRatesData.globalTariffs.inner_tariff
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider inset> </v-divider>
                <!-- picking by lines -->
                <v-row class="pt-4">
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .picking_by_lines_union
                      "
                      label="Picking By Lines"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .picking_by_lines_union
                      "
                      label="First Piece Picking By Lines Tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .first_picking_by_lines_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .first_picking_by_lines_tariff,
                          companyRatesData.globalTariffs
                            .first_picking_by_lines_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .picking_by_lines_union
                      "
                      label="Secondary Picking By Lines Tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .secondary_picking_by_lines_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .secondary_picking_by_lines_tariff,
                          companyRatesData.globalTariffs
                            .secondary_picking_by_lines_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!--  Inbound Putaway -->
                <v-row>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .inbound_putaway_union
                      "
                      label="Inbound Putaway (By Lines)"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Inbound Sku Tariff"
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .inbound_putaway_union
                      "
                      v-model="
                        selectedHistoricRates.globalTariffs.inbound_sku_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .inbound_sku_tariff,
                          companyRatesData.globalTariffs.inbound_sku_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-divider vertical></v-divider>
                  <!-- INBOUND BY PIECES -->
                  <v-col cols="3">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .inbound_by_pieces_union
                      "
                      label="Inbound Putaway (By Pieces)"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Inbound Tariff"
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .inbound_by_pieces_union
                      "
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .inbound_by_pieces_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .inbound_by_pieces_tariff,
                          companyRatesData.globalTariffs
                            .inbound_by_pieces_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Inbound Quality Check -->
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs.inbound_qc_union
                      "
                      label="Inbound Quality Check"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs.inbound_qc_union
                      "
                      label="Inbound QC Percentage (0.1 = 10%)"
                      v-model="
                        selectedHistoricRates.globalTariffs.inbound_qc_perc
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs.inbound_qc_perc,
                          companyRatesData.globalTariffs.inbound_qc_perc
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs.inbound_qc_union
                      "
                      label="Inbound QC Tariff"
                      :value="
                        selectedHistoricRates.globalTariffs.inbound_qc_tariff
                      "
                      v-model="
                        selectedHistoricRates.globalTariffs.inbound_qc_tariff
                      "
                      :rules="
                        selectedHistoricRates.globalTariffs.inbound_qc_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs.inbound_qc_tariff,
                          companyRatesData.globalTariffs.inbound_qc_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- returns  -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      @change="checkSelection('returns')"
                      v-model="
                        selectedHistoricRates.globalTariffs.returns_union
                      "
                      label="Returns"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="selectedHistoricRates.globalTariffs.returns_union"
                      label="Return Piece Tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs.return_piece_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .return_piece_tariff,
                          companyRatesData.globalTariffs.return_piece_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="2">
                    <v-checkbox
                      @change="checkSelection('returns_discount')"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .returns_discount_union
                      "
                      label="Returns (2nd Piece Discount)"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .returns_discount_union
                      "
                      label="First Piece Returns (2nd Piece Discount)  Tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .first_returns_discount_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .first_returns_discount_tariff,
                          companyRatesData.globalTariffs
                            .first_returns_discount_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .returns_discount_union
                      "
                      label=" Secondary Returns (2nd Piece Discount) Tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .secondary_returns_discount_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .secondary_returns_discount_tariff,
                          companyRatesData.globalTariffs
                            .secondary_returns_discount_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- Detailed Pack -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs.detailed_pack_union
                      "
                      label="Detailed Pack"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs.detailed_pack_union
                      "
                      label="Detailed Pack Charge"
                      v-model="
                        selectedHistoricRates.globalTariffs.detailed_charge
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs.detailed_charge,
                          companyRatesData.globalTariffs.detailed_charge
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <!-- consumables -->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Consumables</h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs.consumables_union
                      "
                      label="Consumables"
                      disabled
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <template
                    v-for="item in selectedHistoricRates.consumableTariffs"
                  >
                    <v-col :key="item.snap_lookup_id" cols="2">
                      <v-text-field
                        v-show="
                          selectedHistoricRates.globalTariffs.consumables_union
                        "
                        :label="item.snap_lookup_text"
                        v-model="item.snap_lookup_value"
                        type="number"
                        outlined
                        readonly
                        :class="compareConsumableTariffs(item)"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </div>
              <v-divider></v-divider>
              <!-- storage -->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Storage</h1>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="1">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs.storage_union
                      "
                      label="Storage"
                      @change="checkSelection('storage_union')"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="11">
                    <v-row
                      v-if="selectedHistoricRates.globalTariffs.storage_union"
                    >
                      <v-col cols="8">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-checkbox
                                v-model="
                                  selectedHistoricRates.globalTariffs
                                    .long_term_storage_union
                                "
                                label="Long Term Storage – 365 Days or Damaged Stock – Double Tariff"
                                disabled
                              ></v-checkbox>
                            </span>
                          </template>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="selectedHistoricRates.globalTariffs.storage_union"
                    >
                      <v-col cols="4">
                        <v-checkbox
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .variable_lts_union
                          "
                          label="Variable Long Term Storage"
                          @change="checkSelection('variable_lts')"
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="LTS Days"
                          v-show="
                            selectedHistoricRates.globalTariffs
                              .variable_lts_union
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .variable_lts_days
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs
                                .variable_lts_days,
                              companyRatesData.globalTariffs.variable_lts_days
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="LTS Tariff"
                          v-show="
                            selectedHistoricRates.globalTariffs
                              .variable_lts_union
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .variable_lts_tariff
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs
                                .variable_lts_tariff,
                              companyRatesData.globalTariffs.variable_lts_tariff
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="selectedHistoricRates.globalTariffs.storage_union"
                    >
                      <v-col col="2">
                        <v-checkbox
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .storage_split_pallet
                          "
                          label="Split Pallet Tariff"
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        col="3"
                        v-show="
                          selectedHistoricRates.globalTariffs
                            .storage_split_pallet
                        "
                      >
                        <v-text-field
                          label="Pallet Cutoff"
                          v-show="
                            selectedHistoricRates.globalTariffs
                              .storage_split_pallet
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .storage_split_pallet_cutoff
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs
                                .storage_split_pallet_cutoff,
                              companyRatesData.globalTariffs
                                .storage_split_pallet_cutoff
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col
                        col="3"
                        v-show="
                          selectedHistoricRates.globalTariffs
                            .storage_split_pallet
                        "
                      >
                        <v-text-field
                          label="Pallet Tariff After Split"
                          v-show="
                            selectedHistoricRates.globalTariffs
                              .storage_split_pallet
                          "
                          v-model="
                            selectedHistoricRates.globalTariffs
                              .storage_split_pallet_tariff
                          "
                          type="number"
                          outlined
                          :class="
                            getHistoricRatesComparisonClass(
                              selectedHistoricRates.globalTariffs
                                .storage_split_pallet_tariff,
                              companyRatesData.globalTariffs
                                .storage_split_pallet_tariff
                            )
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        col="3"
                        v-show="
                          selectedHistoricRates.globalTariffs.storage_union
                        "
                      >
                        <h2>Storage Tariffs</h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <template
                        v-for="item in selectedHistoricRates.storageTariffs"
                      >
                        <v-col :key="item.tariff_id" cols="3">
                          <v-text-field
                            v-show="
                              selectedHistoricRates.globalTariffs.storage_union
                            "
                            :id="String(item.tariff_id)"
                            :label="item.tariff_type"
                            v-model="item.value"
                            type="number"
                            outlined
                            :class="compareStorageTariffs(item)"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </template>
                    </v-row>
                    <!-- storage sizes -->
                    <v-row>
                      <v-col
                        col="3"
                        v-show="
                          selectedHistoricRates.globalTariffs.storage_union
                        "
                      >
                        <h2>Storage Sizes</h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <template
                        v-for="item in selectedHistoricRates.storageSizes"
                      >
                        <v-col :key="item.storage_id" cols="2">
                          <v-select
                            v-show="
                              selectedHistoricRates.globalTariffs.storage_union
                            "
                            :label="item.storage_size"
                            :items="selectedHistoricRates.storageTariffs"
                            item-text="tariff_type"
                            item-value="tariff_id"
                            v-model="item.tariff_id"
                            outlined
                            dense
                            readonly
                          ></v-select>
                        </v-col>
                      </template>
                    </v-row>
                    <v-divider />
                  </v-col>
                </v-row>
                <!-- storage by cube -->
                <v-row>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .storage_by_cube_union
                      "
                      @change="checkSelection('storage_by_cube_union')"
                      label="Storage By Cube"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Storage By Cube Tariff"
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .storage_by_cube_union
                      "
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .storage_by_cube_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .storage_by_cube_tariff,
                          companyRatesData.globalTariffs.storage_by_cube_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- LTS by cubic meter -->
                <v-row>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .lts_by_cubic_meter_union
                      "
                      label="Long Term Storage By Cubic Meter"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Long Term Storage By Cubic Meter Tariff"
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .lts_by_cubic_meter_union
                      "
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .lts_by_cubic_meter_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .lts_by_cubic_meter_tariff,
                          companyRatesData.globalTariffs
                            .lts_by_cubic_meter_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <!-- Charge by sku -->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Charge By SKU</h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs.charge_by_sku_union
                      "
                      label="Charge by SKU"
                      disabled
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row
                  v-if="selectedHistoricRates.globalTariffs.charge_by_sku_union"
                >
                </v-row>
              </div>
              <v-divider></v-divider>

              <!--  inbound product labelling per piece  -->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Inbound Product Labelling Per Piece</h1>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .inbound_prod_labelling_per_piece_union
                      "
                      label="Inbound Product Labelling Per Piece"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .inbound_prod_labelling_per_piece_union
                      "
                      label="Inbound Product Labelling Per Piece tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .inbound_prod_labelling_per_piece_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .inbound_prod_labelling_per_piece_tariff,
                          companyRatesData.globalTariffs
                            .inbound_prod_labelling_per_piece_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>

              <!--  Vendor Surcharge  -->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Vendor Surcharge</h1>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .vendor_surcharge_union
                      "
                      label="Vendor Surcharge"
                      disabled
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-show="
                        selectedHistoricRates.globalTariffs
                          .vendor_surcharge_union
                      "
                      label="Vendor Surcharge tariff"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .vendor_surcharge_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .vendor_surcharge_tariff,
                          companyRatesData.globalTariffs.vendor_surcharge_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>

              <!--  Palletways  Consumables-->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Palletways Consumable Tariffs</h1>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Loading Out"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .palletways_loading_out_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .palletways_loading_out_tariff,
                          companyRatesData.globalTariffs
                            .palletways_loading_out_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Strapping"
                      v-model="
                        selectedHistoricRates.globalTariffs
                          .palletways_strapping_tariff
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .palletways_strapping_tariff,
                          companyRatesData.globalTariffs
                            .palletways_strapping_tariff
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Wood/wrap"
                      v-model="
                        selectedHistoricRates.globalTariffs.palletways_woodwrap
                      "
                      type="number"
                      outlined
                      :class="
                        getHistoricRatesComparisonClass(
                          selectedHistoricRates.globalTariffs
                            .palletways_woodwrap,
                          companyRatesData.globalTariffs.palletways_woodwrap
                        )
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
            </v-form>
          </v-card>
        </v-col>

        <!-- current rates -->
        <v-col :cols="selectedHistoricRates ? 6 : 12">
          <v-card v-if="companyRatesData" class="pa-2">
            <v-form
              @submit.prevent
              class="ma-3"
              ref="form"
              v-model="validForm"
              lazy-validation
              :readonly="viewOnlyUser"
            >
              <div class="container pa-2">
                <v-row>
                  <v-col cols="8">
                    <h1>Current Rates</h1>
                  </v-col>
                  <v-col cols="4">
                    <b>Last Updated On:</b>
                    {{
                      formatDateString(companyRatesData.globalTariffs.createdAt)
                    }}
                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <!-- Inbounds tariff -->
                <v-row>
                  <v-col cols="2">
                    <b>Inbound Unloading Charges:</b>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Palletised Inbounds Tariff"
                      :value="
                        companyRatesData.globalTariffs
                          .palletised_inbounds_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs
                          .palletised_inbounds_tariff
                      "
                      :rules="requiredRule"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                    <v-col cols="2">
                    <v-text-field
                      label="Carton Inbounds Tariff"
                      :value="
                        companyRatesData.globalTariffs
                          .carton_inbound_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs
                          .carton_inbound_tariff
                      "
                      :rules="requiredRule"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>

                   <v-col cols="2">
                    <v-text-field
                      label="20 Foot Loose Cartons"
                      :value="
                        companyRatesData.globalTariffs
                           ['20_ft_lo_cart_inbounds_tariff']
                      "
                      v-model="
                        companyRatesData.globalTariffs
                           ['20_ft_lo_cart_inbounds_tariff']
                      "
                      :rules="requiredRule"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>

                       <v-col cols="2">
                    <v-text-field
                      label="40 Foot Loose Cartons"
                      :value="
                        companyRatesData.globalTariffs
                           ['40_ft_lo_cart_inbounds_tariff']
                      "
                      v-model="
                        companyRatesData.globalTariffs
                           ['40_ft_lo_cart_inbounds_tariff']
                      "
                      :rules="requiredRule"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>

                       <v-col cols="2">
                    <v-text-field
                      label="20 Foot High Cube Loose Cartons"
                      :value="
                        companyRatesData.globalTariffs
                           ['20_ft_cube_lo_cart_inbounds_tariff']
                      "
                      v-model="
                        companyRatesData.globalTariffs
                           ['20_ft_cube_lo_cart_inbounds_tariff']
                      "
                      :rules="requiredRule"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>

                       <v-col cols="2">
                    <v-text-field
                      label="40 Foot High Cube Loose Cartons"
                      :value="
                        companyRatesData.globalTariffs
                           ['40_ft_cube_lo_cart_inbounds_tariff']
                      "
                      v-model="
                        companyRatesData.globalTariffs
                           ['40_ft_cube_lo_cart_inbounds_tariff']
                      "
                      :rules="requiredRule"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>


                </v-row>
                <v-divider></v-divider>
                <!-- Amazon surcharge -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        companyRatesData.globalTariffs.amazon_surcharge_union
                      "
                      label="Amazon Surcharge"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Amazon Charge"
                      v-show="
                        companyRatesData.globalTariffs.amazon_surcharge_union
                      "
                      :value="companyRatesData.globalTariffs.amazon_charge"
                      v-model="companyRatesData.globalTariffs.amazon_charge"
                      :rules="
                        companyRatesData.globalTariffs.amazon_surcharge_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2"> </v-col>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="companyRatesData.globalTariffs.sfp_union"
                      label="SFP Surcharge"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="SFP Charge"
                      v-show="companyRatesData.globalTariffs.sfp_union"
                      :value="companyRatesData.globalTariffs.sfp_tariff"
                      v-model="companyRatesData.globalTariffs.sfp_tariff"
                      :rules="
                        companyRatesData.globalTariffs.sfp_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- order admin -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="companyRatesData.globalTariffs.order_admin_union"
                      label="Order Admin"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="companyRatesData.globalTariffs.order_admin_union"
                      label="Order Admin Tariff"
                      :value="companyRatesData.globalTariffs.order_admin_tariff"
                      v-model="
                        companyRatesData.globalTariffs.order_admin_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.order_admin_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- label paperwork -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        companyRatesData.globalTariffs.label_paperwork_union
                      "
                      label="Label Paperwork"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        companyRatesData.globalTariffs.label_paperwork_union
                      "
                      label="Label Tariff"
                      :value="companyRatesData.globalTariffs.label_tariff"
                      v-model="companyRatesData.globalTariffs.label_tariff"
                      :rules="
                        companyRatesData.globalTariffs.label_paperwork_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- loadout -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="companyRatesData.globalTariffs.load_out_union"
                      label="Loadout"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="companyRatesData.globalTariffs.load_out_union"
                      label="Loadout Tariff"
                      :value="companyRatesData.globalTariffs.loadout_tariff"
                      v-model="companyRatesData.globalTariffs.loadout_tariff"
                      :rules="
                        companyRatesData.globalTariffs.load_out_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- picking -->
                <v-row class="mb-n6">
                  <v-col cols="2">
                    <v-checkbox
                      @change="checkSelection('picking')"
                      v-model="companyRatesData.globalTariffs.picking_union"
                      label="Picking"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="10">
                    <v-row>
                      <v-col cols="2">
                        <v-text-field
                          label="Secondary Min (N.Pieces)"
                          v-show="companyRatesData.globalTariffs.picking_union"
                          :value="companyRatesData.globalTariffs.secondary_min"
                          v-model="companyRatesData.globalTariffs.secondary_min"
                          :rules="
                            companyRatesData.globalTariffs.picking_union
                              ? lessThanTwoRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          label="Secondary Max (N.Pieces)"
                          v-show="companyRatesData.globalTariffs.picking_union"
                          :value="companyRatesData.globalTariffs.secondary_max"
                          v-model="companyRatesData.globalTariffs.secondary_max"
                          :rules="
                            companyRatesData.globalTariffs.picking_union
                              ? lessThanTwoRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="2">
                        <v-text-field
                          label="First Piece Tariff"
                          v-show="companyRatesData.globalTariffs.picking_union"
                          :value="
                            companyRatesData.globalTariffs.first_piece_tariff
                          "
                          v-model="
                            companyRatesData.globalTariffs.first_piece_tariff
                          "
                          :rules="
                            companyRatesData.globalTariffs.picking_union
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          label="Secondary Tariff"
                          v-show="companyRatesData.globalTariffs.picking_union"
                          :value="
                            companyRatesData.globalTariffs.secondary_tariff
                          "
                          v-model="
                            companyRatesData.globalTariffs.secondary_tariff
                          "
                          :rules="
                            companyRatesData.globalTariffs.picking_union
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          label="Supplementary Tariff"
                          v-show="companyRatesData.globalTariffs.picking_union"
                          :value="
                            companyRatesData.globalTariffs.supplementary_tariff
                          "
                          v-model="
                            companyRatesData.globalTariffs.supplementary_tariff
                          "
                          :rules="
                            companyRatesData.globalTariffs.picking_union
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          v-show="companyRatesData.globalTariffs.picking_union"
                          v-model="
                            companyRatesData.globalTariffs.picking_break_per_sku
                          "
                          label="Break Per SKU"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="2">
                        <v-checkbox
                          v-show="companyRatesData.globalTariffs.picking_union"
                          v-model="
                            companyRatesData.globalTariffs.charge_pallet_tariff
                          "
                          label="Charge Pallet Tariff"
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Pallet Tariff"
                          v-show="
                            companyRatesData.globalTariffs.charge_pallet_tariff
                          "
                          :value="companyRatesData.globalTariffs.pallet_tariff"
                          v-model="companyRatesData.globalTariffs.pallet_tariff"
                          :rules="
                            companyRatesData.globalTariffs.charge_pallet_tariff
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          v-show="companyRatesData.globalTariffs.picking_union"
                          v-model="
                            companyRatesData.globalTariffs.charge_box_tariff
                          "
                          label="Charge Box Tariff"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-show="
                            companyRatesData.globalTariffs.charge_box_tariff
                          "
                          label="Carton Tariff"
                          :value="companyRatesData.globalTariffs.carton_tariff"
                          v-model="companyRatesData.globalTariffs.carton_tariff"
                          :rules="
                            companyRatesData.globalTariffs.charge_box_tariff
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          v-show="companyRatesData.globalTariffs.picking_union"
                          v-model="
                            companyRatesData.globalTariffs.charge_inner_tariff
                          "
                          label="Charge Inner Tariff"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          label="Inner Tariff"
                          v-show="
                            companyRatesData.globalTariffs.charge_inner_tariff
                          "
                          :value="companyRatesData.globalTariffs.inner_tariff"
                          v-model="companyRatesData.globalTariffs.inner_tariff"
                          :rules="
                            companyRatesData.globalTariffs.charge_inner_tariff
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider inset> </v-divider>
                <!-- picking by lines -->
                <v-row class="pt-4">
                  <v-col cols="2">
                    <v-checkbox
                      @change="checkSelection('picking_by_lines')"
                      v-model="
                        companyRatesData.globalTariffs.picking_by_lines_union
                      "
                      label="Picking By Lines"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        companyRatesData.globalTariffs.picking_by_lines_union
                      "
                      label="First Piece Picking By Lines Tariff"
                      :value="
                        companyRatesData.globalTariffs
                          .first_picking_by_lines_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs
                          .first_picking_by_lines_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.picking_by_lines_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        companyRatesData.globalTariffs.picking_by_lines_union
                      "
                      label="Secondary Picking By Lines Tariff"
                      :value="
                        companyRatesData.globalTariffs
                          .secondary_picking_by_lines_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs
                          .secondary_picking_by_lines_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.picking_by_lines_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!--  Inbound Putaway -->
                <v-row>
                  <v-col cols="3">
                    <v-checkbox
                      @change="checkSelection('inbound_putaway')"
                      v-model="
                        companyRatesData.globalTariffs.inbound_putaway_union
                      "
                      label="Inbound Putaway (By Lines)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Inbound Sku Tariff"
                      v-show="
                        companyRatesData.globalTariffs.inbound_putaway_union
                      "
                      :value="companyRatesData.globalTariffs.inbound_sku_tariff"
                      v-model="
                        companyRatesData.globalTariffs.inbound_sku_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.inbound_putaway_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-divider vertical></v-divider>
                  <!-- INBOUND BY PIECES -->
                  <v-col cols="3">
                    <v-checkbox
                      @change="checkSelection('inbound_by_pieces')"
                      v-model="
                        companyRatesData.globalTariffs.inbound_by_pieces_union
                      "
                      label="Inbound Putaway (By Pieces)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Inbound Tariff"
                      v-show="
                        companyRatesData.globalTariffs.inbound_by_pieces_union
                      "
                      :value="
                        companyRatesData.globalTariffs.inbound_by_pieces_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs.inbound_by_pieces_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.inbound_by_pieces_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Inbound Quality Check -->
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="companyRatesData.globalTariffs.inbound_qc_union"
                      label="Inbound Quality Check"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-show="companyRatesData.globalTariffs.inbound_qc_union"
                      label="Inbound QC Percentage (0.1 = 10%)"
                      :value="companyRatesData.globalTariffs.inbound_qc_perc"
                      v-model="companyRatesData.globalTariffs.inbound_qc_perc"
                      :rules="
                        companyRatesData.globalTariffs.inbound_qc_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-show="companyRatesData.globalTariffs.inbound_qc_union"
                      label="Inbound QC Tariff"
                      :value="companyRatesData.globalTariffs.inbound_qc_tariff"
                      v-model="companyRatesData.globalTariffs.inbound_qc_tariff"
                      :rules="
                        companyRatesData.globalTariffs.inbound_qc_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- returns  -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      @change="checkSelection('returns')"
                      v-model="companyRatesData.globalTariffs.returns_union"
                      label="Returns"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="companyRatesData.globalTariffs.returns_union"
                      label="Return Piece Tariff"
                      :value="
                        companyRatesData.globalTariffs.return_piece_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs.return_piece_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.returns_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="2">
                    <v-checkbox
                      @change="checkSelection('returns_discount')"
                      v-model="
                        companyRatesData.globalTariffs.returns_discount_union
                      "
                      label="Returns (2nd Piece Discount)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        companyRatesData.globalTariffs.returns_discount_union
                      "
                      label="First Piece Returns (2nd Piece Discount)  Tariff"
                      :value="
                        companyRatesData.globalTariffs
                          .first_returns_discount_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs
                          .first_returns_discount_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.returns_discount_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        companyRatesData.globalTariffs.returns_discount_union
                      "
                      label=" Secondary Returns (2nd Piece Discount) Tariff"
                      :value="
                        companyRatesData.globalTariffs
                          .secondary_returns_discount_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs
                          .secondary_returns_discount_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.returns_discount_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- Detailed Pack -->
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        companyRatesData.globalTariffs.detailed_pack_union
                      "
                      label="Detailed Pack"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-show="
                        companyRatesData.globalTariffs.detailed_pack_union
                      "
                      label="Detailed Pack Charge"
                      :value="companyRatesData.globalTariffs.detailed_charge"
                      v-model="companyRatesData.globalTariffs.detailed_charge"
                      :rules="
                        companyRatesData.globalTariffs.detailed_pack_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <!-- consumables -->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Consumables</h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="companyRatesData.globalTariffs.consumables_union"
                      label="Consumables"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <template v-for="item in companyRatesData.consumableTariffs">
                    <v-col :key="item.snap_lookup_id" cols="2">
                      <v-text-field
                        v-show="
                          companyRatesData.globalTariffs.consumables_union
                        "
                        :id="String(item.snap_lookup_id)"
                        :label="item.snap_lookup_text"
                        :value="item.snap_lookup_value"
                        v-model="item.snap_lookup_value"
                        :rules="
                          companyRatesData.globalTariffs.consumables_union
                            ? requiredRule
                            : []
                        "
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </div>
              <v-divider></v-divider>
              <!-- storage -->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Storage</h1>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="1">
                    <v-checkbox
                      v-model="companyRatesData.globalTariffs.storage_union"
                      label="Storage"
                      @change="checkSelection('storage_union')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="11">
                    <v-row v-if="companyRatesData.globalTariffs.storage_union">
                      <v-col cols="8">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-checkbox
                                v-model="
                                  companyRatesData.globalTariffs
                                    .long_term_storage_union
                                "
                                label="Long Term Storage – 365 Days or Damaged Stock – Double Tariff"
                                v-on="on"
                                v-bind="attrs"
                                @change="checkSelection('long_term_storage')"
                              ></v-checkbox>
                            </span>
                          </template>
                          <span
                            >This toggle applies an additional charge equal to
                            the current location tariff for any locations
                            whereby the stock within location has been onsite
                            for greater than 365 days or the stock is
                            categorised as “Damaged” and therefore non moving.
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row v-if="companyRatesData.globalTariffs.storage_union">
                      <v-col cols="4">
                        <v-checkbox
                          v-model="
                            companyRatesData.globalTariffs.variable_lts_union
                          "
                          label="Variable Long Term Storage"
                          @change="checkSelection('variable_lts')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="LTS Days"
                          v-show="
                            companyRatesData.globalTariffs.variable_lts_union
                          "
                          :value="
                            companyRatesData.globalTariffs.variable_lts_days
                          "
                          v-model="
                            companyRatesData.globalTariffs.variable_lts_days
                          "
                          :rules="
                            companyRatesData.globalTariffs.variable_lts_union
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="LTS Tariff"
                          v-show="
                            companyRatesData.globalTariffs.variable_lts_union
                          "
                          :value="
                            companyRatesData.globalTariffs.variable_lts_tariff
                          "
                          v-model="
                            companyRatesData.globalTariffs.variable_lts_tariff
                          "
                          :rules="
                            companyRatesData.globalTariffs.variable_lts_union
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="companyRatesData.globalTariffs.storage_union">
                      <v-col col="2">
                        <v-checkbox
                          v-model="
                            companyRatesData.globalTariffs.storage_split_pallet
                          "
                          label="Split Pallet Tariff"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        col="3"
                        v-show="
                          companyRatesData.globalTariffs.storage_split_pallet
                        "
                      >
                        <v-text-field
                          label="Pallet Cutoff"
                          v-show="
                            companyRatesData.globalTariffs.storage_split_pallet
                          "
                          :value="
                            companyRatesData.globalTariffs
                              .storage_split_pallet_cutoff
                          "
                          v-model="
                            companyRatesData.globalTariffs
                              .storage_split_pallet_cutoff
                          "
                          :rules="
                            companyRatesData.globalTariffs.storage_split_pallet
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col
                        col="3"
                        v-show="
                          companyRatesData.globalTariffs.storage_split_pallet
                        "
                      >
                        <v-text-field
                          label="Pallet Tariff After Split"
                          v-show="
                            companyRatesData.globalTariffs.storage_split_pallet
                          "
                          :value="
                            companyRatesData.globalTariffs
                              .storage_split_pallet_tariff
                          "
                          v-model="
                            companyRatesData.globalTariffs
                              .storage_split_pallet_tariff
                          "
                          :rules="
                            companyRatesData.globalTariffs.storage_split_pallet
                              ? requiredRule
                              : []
                          "
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        col="3"
                        v-show="companyRatesData.globalTariffs.storage_union"
                      >
                        <h2>Storage Tariffs</h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <template v-for="item in companyRatesData.storageTariffs">
                        <v-col :key="item.tariff_id" cols="3">
                          <v-text-field
                            v-show="
                              companyRatesData.globalTariffs.storage_union
                            "
                            :id="String(item.tariff_id)"
                            :label="item.tariff_type"
                            :value="item.value"
                            v-model="item.value"
                            :rules="
                              companyRatesData.globalTariffs.storage_union
                                ? requiredRule
                                : []
                            "
                            type="number"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </template>
                    </v-row>
                    <!-- storage sizes -->
                    <v-row>
                      <v-col
                        col="3"
                        v-show="companyRatesData.globalTariffs.storage_union"
                      >
                        <h2>Storage Sizes</h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <template v-for="item in companyRatesData.storageSizes">
                        <v-col :key="item.storage_id" cols="2">
                          <v-select
                            v-show="
                              companyRatesData.globalTariffs.storage_union
                            "
                            :label="item.storage_size"
                            :items="companyRatesData.storageTariffs"
                            item-text="tariff_type"
                            item-value="tariff_id"
                            v-model="item.tariff_id"
                            :rules="
                              companyRatesData.globalTariffs.storage_union
                                ? requiredRule
                                : []
                            "
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </template>
                    </v-row>
                    <v-divider />
                  </v-col>
                </v-row>
                <!-- storage by cube -->
                <v-row>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="
                        companyRatesData.globalTariffs.storage_by_cube_union
                      "
                      @change="checkSelection('storage_by_cube_union')"
                      label="Storage By Cube"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Storage By Cube Tariff"
                      v-show="
                        companyRatesData.globalTariffs.storage_by_cube_union
                      "
                      :value="
                        companyRatesData.globalTariffs.storage_by_cube_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs.storage_by_cube_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.storage_by_cube_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- LTS by cubic meter -->
                <v-row>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="
                        companyRatesData.globalTariffs.lts_by_cubic_meter_union
                      "
                      label="Long Term Storage By Cubic Meter"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Long Term Storage By Cubic Meter Tariff"
                      v-show="
                        companyRatesData.globalTariffs.lts_by_cubic_meter_union
                      "
                      :value="
                        companyRatesData.globalTariffs.lts_by_cubic_meter_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs.lts_by_cubic_meter_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.lts_by_cubic_meter_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <!-- Charge by sku -->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Charge By SKU</h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="
                        companyRatesData.globalTariffs.charge_by_sku_union
                      "
                      label="Charge by SKU"
                      @change="checkSelection('charge_by_sku_union')"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row
                  v-if="companyRatesData.globalTariffs.charge_by_sku_union"
                >
                  <v-col cols="12">
                    <v-card outlined class="pa-4">
                      <v-form>
                        <!-- wrap search text field in another v-form so readonly property from parent v-form is ignored,
                        this allows 'viewOnly' users to still be able to search -->
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          class="mb-2"
                          label="Search"
                          single-line
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-form>
                      <v-data-table
                        :headers="chargeBySkuHeaders"
                        :items="chargeBySkuData"
                        :items-per-page="10"
                        :search="search"
                        outlined
                      >
                        <template v-slot:item.AttachmentText="{ item }">
                          <v-text-field
                            v-model="item.AttachmentText"
                            type="number"
                            outlined
                            dense
                          ></v-text-field>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            color="primary"
                            :loading="loadingData"
                            depressed
                            @click="saveProductCharge(item)"
                          >
                            save
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>

              <!--  inbound product labelling per piece  -->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Inbound Product Labelling Per Piece</h1>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="
                        companyRatesData.globalTariffs
                          .inbound_prod_labelling_per_piece_union
                      "
                      label="Inbound Product Labelling Per Piece"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-show="
                        companyRatesData.globalTariffs
                          .inbound_prod_labelling_per_piece_union
                      "
                      label="Inbound Product Labelling Per Piece tariff"
                      :value="
                        companyRatesData.globalTariffs
                          .inbound_prod_labelling_per_piece_tariff
                      "
                      v-model="
                        companyRatesData.globalTariffs
                          .inbound_prod_labelling_per_piece_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs
                          .inbound_prod_labelling_per_piece_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>

              <!--  Vendor Surcharge  -->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Vendor Surcharge</h1>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="
                        companyRatesData.globalTariffs.vendor_surcharge_union
                      "
                      label="Vendor Surcharge"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-show="
                        companyRatesData.globalTariffs.vendor_surcharge_union
                      "
                      label="Vendor Surcharge tariff"
                      v-model="
                        companyRatesData.globalTariffs.vendor_surcharge_tariff
                      "
                      :rules="
                        companyRatesData.globalTariffs.vendor_surcharge_union
                          ? requiredRule
                          : []
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>

              <!--  Palletways  Consumables-->
              <div class="container pa-2">
                <v-row>
                  <v-col cols="12">
                    <h1>Palletways Consumable Tariffs</h1>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Loading Out"
                      v-model="
                        companyRatesData.globalTariffs
                          .palletways_loading_out_tariff
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Strapping"
                      v-model="
                        companyRatesData.globalTariffs
                          .palletways_strapping_tariff
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Wood/wrap"
                      v-model="
                        companyRatesData.globalTariffs.palletways_woodwrap
                      "
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>

              <!-- actions/buttons -->
              <div class="container" v-if="!viewOnlyUser">
                <v-row>
                  <v-col align="center">
                    <v-btn
                      :loading="loadingData"
                      :disabled="loadingData"
                      color="primary"
                      outlined
                      class="ml-1 mr-1"
                      @click="revertChanges"
                    >
                      Revert Changes
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      :disabled="!validForm || loadingData"
                      :loading="loadingData"
                      color="primary"
                      class="ml-1 mr-1"
                      @click="saveCompanyRates"
                    >
                      Save Company Rates
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      :disabled="!validForm || loadingData"
                      :loading="loadingData"
                      color="#a82424"
                      class="ml-1 mr-1 white--text"
                      @click="exportRatesToPdf"
                    >
                      Export Rates to Pdf
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :timeout="4000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import CompanySelect from "@/components/forms/CompanySelect";
import BillingSnap from "@/services/BillingSnap.js";
import UserRoles from "@/mixins/UserRoles";
import jsPDF from "jspdf";
export default {
  mixins: [UserRoles],
  components: {
    CompanySelect,
  },
  data() {
    return {
      validForm: true,
      // Title
      title: "Edit Snap Billing Rates",
      // Snackbar
      snackbar: false,
      text: "",
      companySelected: {},
      originalCompanyRatesData: null,
      companyRatesData: null,
      requiredRule: [(v) => !!v || "This field is required"],
      lessThanTwoRule: [
        (v) => (v >= 2 || v === undefined) || "The value must be at least 2",
      ],
      loadingData: false,

      isPrepCompany: false,
      prepCompaniesList: [],
      selectedPrepId: null,

      search: "",
      chargeBySkuData: [],
      chargeBySkuHeaders: [
        { text: "SKU", value: "SKUId" },
        { text: "Company Code", value: "BizId" },
        { text: "SKU Title", value: "Text" },
        { text: "Charge", value: "AttachmentText" },
        { text: "", value: "actions" },
      ],

      historicRates: [],
      selectedHistoricRates: null,
      compareHistoricRates: false,
    };
  },
  methods: {
    getCurrency(number) {
      return "£" + number;
    },
    getPdfSections() {
      const compRates = this.originalCompanyRatesData;
      const globalTariffs = compRates.globalTariffs;
      const sections = [];

      // Palletised Inbound
      let palChar =  "Palletised Inbound Tariff: " +
            this.getCurrency(globalTariffs.palletised_inbounds_tariff)
      let cartChar =  "Carton Inbound Tariff: " +
            this.getCurrency(globalTariffs.carton_inbound_tariff)
      let tFLCarton =  "20 Foot Loose Cartons: " +
            this.getCurrency(globalTariffs['20_ft_lo_cart_inbounds_tariff'])
      let fFLCarton =  "40 Foot Loose Cartons: " +
            this.getCurrency(globalTariffs['40_ft_lo_cart_inbounds_tariff'])
      let tCubeCarton =  "20 Foot High Cube Loose Cartons: " +
            this.getCurrency(globalTariffs['20_ft_cube_lo_cart_inbounds_tariff'])
      let fCubeCarton =  "40 Foot High Cube Loose Cartons: " +
            this.getCurrency(globalTariffs['40_ft_cube_lo_cart_inbounds_tariff'])
      sections.push({
        title: "Inbound Unloading Charges",
        charges: [palChar ,cartChar, tFLCarton, fFLCarton,tCubeCarton,fCubeCarton],
      });

      // Amazon Charges
      if (globalTariffs.amazon_surcharge_union || globalTariffs.sfp_union) {
        const charges = [];
        if (globalTariffs.amazon_surcharge_union) {
          charges.push(
            "Amazon Tariff: " + this.getCurrency(globalTariffs.amazon_charge)
          );
        }
        if (globalTariffs.sfp_union) {
          charges.push(
            "SFP Tariff: " + this.getCurrency(globalTariffs.sfp_tariff)
          );
        }
        sections.push({
          title: "Amazon",
          charges: charges,
        });
      }

      // Order Admin
      if (globalTariffs.order_admin_union) {
        sections.push({
          title: "Order Admin",
          charges: [
            "Order Admin Tariff: " +
              this.getCurrency(globalTariffs.order_admin_tariff),
          ],
        });
      }

      // Label Paperwork
      if (globalTariffs.label_paperwork_union) {
        sections.push({
          title: "Label Paperwork",
          charges: [
            "Label Paperwork Tariff: " +
              this.getCurrency(globalTariffs.label_tariff),
          ],
        });
      }

      // Picking
      if (globalTariffs.picking_union) {
        // Picking
        const first = `First Piece Tariff: ${this.getCurrency(
          globalTariffs.first_piece_tariff
        )}`;
        const minRef = parseInt(globalTariffs.secondary_min);
        const maxRef = parseInt(globalTariffs.secondary_max);
        const second = `Pieces ${minRef} to ${maxRef}: ${this.getCurrency(
          globalTariffs.secondary_tariff
        )}`;
        const sup =
          "Supplementary Tariff: " +
          this.getCurrency(globalTariffs.supplementary_tariff);
        const charges = [first, second, sup];

        if (globalTariffs.picking_break_per_sku) {
          charges.push("Using Break Per SKU");
        }

        if (globalTariffs.charge_pallet_tariff) {
          charges.push(
            "Pallet Tariff: " + this.getCurrency(globalTariffs.pallet_tariff)
          );
        }
        if (globalTariffs.charge_box_tariff) {
          charges.push(
            "Carton Tariff: " + this.getCurrency(globalTariffs.carton_tariff)
          );
        }
        if (globalTariffs.charge_inner_tariff) {
          charges.push(
            "Inner Tariff: " + this.getCurrency(globalTariffs.inner_tariff)
          );
        }

        sections.push({
          title: "Picking",
          charges: charges,
        });
      }

      // Picking By Lines
      if (globalTariffs.picking_by_lines_union) {
        const charges = [
          "First Piece Tariff: " +
            this.getCurrency(globalTariffs.first_picking_by_lines_tariff),
          "Secondary Tariff: " +
            this.getCurrency(globalTariffs.secondary_picking_by_lines_tariff),
        ];
        sections.push({
          title: "Picking (By Lines)",
          charges: charges,
        });
      }

      // Inbound
      if (
        globalTariffs.inbound_putaway_union ||
        globalTariffs.inbound_by_pieces_union ||
        globalTariffs.inbound_qc_union
      ) {
        const charges = [];
        if (globalTariffs.inbound_putaway_union) {
          charges.push(
            "Inbound Putaway (By Lines): " +
              this.getCurrency(globalTariffs.inbound_sku_tariff)
          );
        }
        if (globalTariffs.inbound_by_pieces_union) {
          charges.push(
            "Inbound Putaway (By Pieces): " +
              this.getCurrency(globalTariffs.inbound_by_pieces_tariff)
          );
        }
        if (globalTariffs.inbound_qc_union) {
          let perc = globalTariffs.inbound_qc_perc * 100;
          charges.push(
            `Inbound Quality Check - ${perc}% at ${this.getCurrency(
              globalTariffs.inbound_qc_tariff
            )}`
          );
        }

        sections.push({
          title: "Inbounds",
          charges: charges,
        });
      }

      // Returns
      if (globalTariffs.returns_union || globalTariffs.returns_discount_union) {
        const charges = [];
        if (globalTariffs.returns_union) {
          charges.push(
            "Return Piece Tariff: " +
              this.getCurrency(globalTariffs.return_piece_tariff)
          );
        }
        if (globalTariffs.returns_discount_union) {
          charges.push(
            "First Piece (2nd Piece Discount) Tariff: " +
              this.getCurrency(globalTariffs.first_returns_discount_tariff)
          );
          charges.push(
            "Secondary (2nd Piece Discount) Tariff: " +
              this.getCurrency(globalTariffs.secondary_returns_discount_tariff)
          );
        }
        sections.push({
          title: "Returns",
          charges: charges,
        });
      }

      // Detailed Pack
      if (globalTariffs.detailed_pack_union) {
        sections.push({
          title: "Detailed Pack",
          charges: [
            "Detailed Pack Tariff: " +
              this.getCurrency(globalTariffs.detailed_charge),
          ],
        });
      }
      // Consumables section will not be shown
      // Storage
      if (globalTariffs.storage_union) {
        const charges = compRates.storageTariffs.map(
          (el) => `${el.tariff_type}: ${this.getCurrency(el.value)}`
        );
        sections.push({
          title: "Storage",
          charges: charges,
        });
      }

      if (
        globalTariffs.storage_union &&
        globalTariffs.long_term_storage_union
      ) {
        sections.push({
          title:
            "Double Tariff Applied for Long Term Storage - 365 Days or Damaged Stock",
          charges: [],
        });
      }
      if (globalTariffs.storage_union && globalTariffs.variable_lts_union) {
        sections.push({
          title: "Variable Long Term Storage",
          charges: [
            "Days: " + globalTariffs.variable_lts_days,
            "Tariff: " + this.getCurrency(globalTariffs.variable_lts_tariff),
          ],
        });
      }

      if (globalTariffs.storage_union && globalTariffs.storage_by_cube_union) {
        sections.push({
          title: "Storage By Cubic Meter",
          charges: [
            "Storage by Cubic Meter Charge: " +
              this.getCurrency(globalTariffs.storage_by_cube_tariff),
          ],
        });
      }

      if (
        globalTariffs.storage_union &&
        globalTariffs.lts_by_cubic_meter_union
      ) {
        sections.push({
          title: "Long Term Storage by Cubic Meter",
          charges: [
            "LTS by Cubic Meter Tariff: " +
              this.getCurrency(globalTariffs.lts_by_cubic_meter_tariff),
          ],
        });
      }

      //Charge By SKU
      if (globalTariffs.charge_by_sku_union) {
        const filtered = this.chargeBySkuData.filter((el) => {
          return el.AttachmentText;
        });
        if (filtered.length) {
          let charges = [];
          charges = filtered.map(
            (el) => `${el.SKUId}: ${this.getCurrency(el.AttachmentText)}`
          );
          sections.push({
            title: "Charge By SKU",
            charges: charges,
          });
        }
      }

      //Inbound Product Labelling Per Piece
      if (globalTariffs.inbound_prod_labelling_per_piece_union) {
        sections.push({
          title: "Inbound Product Labelling ",
          charges: [
            "Product Labelling Per Piece Tariff: " +
              this.getCurrency(
                globalTariffs.inbound_prod_labelling_per_piece_tariff
              ),
          ],
        });
      }

      //Vendor Surcharge
      if (globalTariffs.vendor_surcharge_union) {
        sections.push({
          title: "Vendor Surcharge",
          charges: [
            "Vendor Surcharge Tariff: " +
              this.getCurrency(globalTariffs.vendor_surcharge_tariff),
          ],
        });
      }

      // Palletways Consumable Tariffs
      sections.push({
        title: "Palletways Consumable Tariffs",
        charges: [
          "Loading Out: " +
            this.getCurrency(globalTariffs.palletways_loading_out_tariff),
          "Strapping: " +
            this.getCurrency(globalTariffs.palletways_strapping_tariff),
          "Wood/wrap: " + this.getCurrency(globalTariffs.palletways_woodwrap),
        ],
      });

      return sections;
    },
    exportRatesToPdf() {
      this.loadingData = true;
      //change options based on screen size

      const sections = this.getPdfSections();

      // Create a new jsPDF instance
      const doc = new jsPDF();

      const docWidth = doc.internal.pageSize.getWidth();
      const docHeight = doc.internal.pageSize.getHeight();
      const margin = 20;

      // Define the starting position
      let y = 13;
      let x = 10;

      const title = `Billing Rates For ${this.companySelected.name}`;

      // Page title
      doc.setFontSize(20);
      doc.setFontStyle("bold");
      doc.setFont("helvetica");
      doc.text(10, y, title);

      y += 15;

      // Loop through the sections and add them to the PDF
      sections.forEach((section) => {
        // Define the number of columns and column width
        const numColumns = 3;
        const columnWidth = docWidth / numColumns;

        // Calculate the height required for the content of the current section
        doc.setFontSize(12);
        // Add section title
        doc.setFontStyle("bold");
        doc.text(10, y, section.title);
        doc.setFontStyle("normal");

        const contentHeight = doc.getTextDimensions(section.title).h - 6;
        y += contentHeight;

        // loop through section charges
        const charges = section.charges;
        doc.setFontSize(10);
        charges.forEach((charge, index) => {
          // Add new page if y exceeds margin
          if (y + margin > docHeight) {
            doc.addPage(); // Add a new page if content won't fit
            y = margin; // Reset yPos for the new page
          }

          const contentHeight = doc.getTextDimensions(charge).h - 5;
          doc.text(x, y, charge);
          // Move the cursor to the next column or the beginning of the next row
          if ((index + 1) % numColumns === 0) {
            // Move to the next row
            x = 10;
            y += contentHeight; // You can adjust the vertical spacing as needed
          } else {
            // Move to the next column
            x += columnWidth;
          }
        });

        // reset x position
        x = 10;
        // Add a small gap between sections
        y += contentHeight + 10;

        // Add new page if y exceeds margin
        if (y + margin + 10 > docHeight) {
          doc.addPage(); // Add a new page if content won't fit
          y = margin; // Reset yPos for the new page
        }
      });

      // Save the PDF
      doc.save("rates.pdf");
      this.loadingData = false;
    },

    checkCompanySelected(company) {
      this.companySelected = company;
      this.companyRatesData = null;
      if (
        company.name.toUpperCase() === "PREPMATE" ||
        company.code.toUpperCase() === "V08"
      ) {
        this.loadingData = true;
        this.isPrepCompany = true;
        BillingSnap.getPrepCompanies(company.code)
          .then((response) => {
            this.loadingData = false;
            this.prepCompaniesList = response;
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = `${error.message}`;
            this.loadingData = false;
          });
      } else {
        this.isPrepCompany = false;
        this.selectedPrepId = null;
        this.loadCompanyRates(company);
      }
    },
    saveProductCharge(item) {
      this.loadingData = true;
      let reqBody = {
        companyCode: item.BizId,
        sku: item.SKUId,
        billingCharge: item.AttachmentText,
      };
      BillingSnap.saveProductBillingCharge(reqBody)
        .then((response) => {
          this.loadingData = false;
          this.snackbar = true;
          this.text = `${response.data}`;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loadingData = false;
        });
    },
    getHistoricCompanyRates(companyId) {
      BillingSnap.getHistoricCompanyRates(companyId)
        .then((response) => {
          this.historicRates = response;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
        });
    },
    formatDateString(date) {
      if (!date) return "";
      return date.slice(0, 19).replace("T", " ");
    },
    clearLoadedRates() {
      this.originalCompanyRatesData = null;
      this.companyRatesData = null;
      this.selectedHistoricRates = null;
      this.historicRates = [];
      this.compareHistoricRates = false;
    },
    loadCompanyRates(company) {
      this.loadingData = true;
      this.companySelected = company;
      let prepId = false;
      if (this.isPrepCompany && this.selectedPrepId) {
        prepId = this.selectedPrepId;
      }
      this.clearLoadedRates();
      BillingSnap.getCompanyRates(company.id, prepId)
        .then((response) => {
          this.loadingData = false;
          if (!response.hasOwnProperty("globalTariffs")) {
            response.globalTariffs = {};
          } else if (response.globalTariffs.charge_by_sku_union) {
            this.getProductBillingCharges();
          }
          response.companyId = company.id;
          this.companyRatesData = response;
          this.getHistoricCompanyRates(company.id);

          //clone data without reference (dirty method)
          this.originalCompanyRatesData = JSON.parse(JSON.stringify(response));
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loadingData = false;
        });
    },
    saveCompanyRates() {
      if (!this.$refs.form.validate()) {
        this.snackbar = true;
        this.text = "Not ready to be saved, check the form for required fields";
        return;
      }
      if (this.isPrepCompany && this.selectedPrepId) {
        this.companyRatesData.prepId = this.selectedPrepId;
      }
      this.loadingData = true;
      BillingSnap.saveCompanyRates(this.companyRatesData)
        .then((response) => {
          this.snackbar = true;
          this.text = response;
          this.loadingData = false;
          this.loadCompanyRates(this.companySelected);
        })
        .catch((error) => {
          this.snackbar = true;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.text = `${error.response.data.message}`;
          } else {
            this.text = `${error.message}`;
          }
          this.loadingData = false;
        });
    },
    checkSelection(selection) {
      switch (selection) {
        case "returns":
          if (this.companyRatesData.globalTariffs.returns_union) {
            this.companyRatesData.globalTariffs.returns_discount_union = false;
          }
          break;
        case "returns_discount":
          if (this.companyRatesData.globalTariffs.returns_discount_union) {
            this.companyRatesData.globalTariffs.returns_union = false;
          }
          break;
        case "picking":
          if (this.companyRatesData.globalTariffs.picking_union) {
            this.companyRatesData.globalTariffs.picking_by_lines_union = false;
          }
          break;
        case "picking_by_lines":
          if (this.companyRatesData.globalTariffs.picking_by_lines_union) {
            this.companyRatesData.globalTariffs.picking_union = false;
          }
          break;
        case "inbound_putaway":
          if (this.companyRatesData.globalTariffs.inbound_putaway_union) {
            this.companyRatesData.globalTariffs.inbound_by_pieces_union = false;
          }
          break;
        case "inbound_by_pieces":
          if (this.companyRatesData.globalTariffs.inbound_by_pieces_union) {
            this.companyRatesData.globalTariffs.inbound_putaway_union = false;
          }
          break;
        case "variable_lts":
          if (this.companyRatesData.globalTariffs.variable_lts_union) {
            this.companyRatesData.globalTariffs.long_term_storage_union = false;
          }
          break;
        case "long_term_storage":
          if (this.companyRatesData.globalTariffs.long_term_storage_union) {
            this.companyRatesData.globalTariffs.variable_lts_union = false;
          }
          break;
        case "charge_by_sku_union":
          if (this.companyRatesData.globalTariffs.charge_by_sku_union) {
            this.getProductBillingCharges();
          }
          break;
        case "storage_by_cube_union":
          if (this.companyRatesData.globalTariffs.storage_by_cube_union) {
            this.companyRatesData.globalTariffs.storage_union = false;
          }
          break;
        case "storage_union":
          if (this.companyRatesData.globalTariffs.storage_union) {
            this.companyRatesData.globalTariffs.storage_by_cube_union = false;
          }
          break;
      }
    },
    getProductBillingCharges() {
      this.loadingData = true;
      BillingSnap.getProductBillingCharges(this.companySelected.code)
        .then((response) => {
          this.loadingData = false;

          this.chargeBySkuData = response;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loadingData = false;
        });
    },
    revertChanges() {
      this.companyRatesData = JSON.parse(
        JSON.stringify(this.originalCompanyRatesData)
      );
    },
    compareStorageTariffs(historicStorage) {
      let currentStorageTariffs = this.companyRatesData.storageTariffs;
      let matchedCurrent = currentStorageTariffs.find(
        (tar) => tar.tariff_type === historicStorage.tariff_type
      );
      let historicVal = historicStorage.value;
      let currentVal = matchedCurrent ? matchedCurrent.value : 0;
      return this.getHistoricRatesComparisonClass(historicVal, currentVal);
    },
    compareConsumableTariffs(historicConsumable) {
      let currentTariffs = this.companyRatesData.consumableTariffs;
      let matchedCurrent = currentTariffs.find(
        (tar) => tar.snap_lookup_text === historicConsumable.snap_lookup_text
      );
      let historicVal = historicConsumable.snap_lookup_value;
      let currentVal = matchedCurrent ? matchedCurrent.snap_lookup_value : 0;
      return this.getHistoricRatesComparisonClass(historicVal, currentVal);
    },
    getHistoricRatesComparisonClass(previousValue, currentValue) {
      if (previousValue == currentValue) {
        return "";
      } else if (parseFloat(previousValue) > parseFloat(currentValue)) {
        return "text-green";
      } else {
        return "text-red";
      }
    },
  },
  computed: {
    loading: function () {
      return this.$root.sharedData.state.loading;
    },
  },
  watch: {
    compareHistoricRates(val) {
      if (!val) {
        this.selectedHistoricRates = null;
      }
    },
  },
};
</script>

<style>
h1 {
  font-size: 150% !important;
}
.big-container {
  max-width: 1800px;
  margin: 0 auto;
  font-size: 70% !important;
}
.big-container .v-label {
  font-size: 12px !important;
}
.big-container input {
  font-size: 12px !important;
}

.text-green input {
  color: rgb(31, 193, 31) !important;
  font-weight: bold;
}
.text-red input {
  color: rgb(241, 40, 50) !important;
  font-weight: bold;
}
</style>
